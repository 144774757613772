import React from "react";

const arrowStyle = {
    width: "16px",
    left: "-3px",
};

export const Arrow = ({ color = "black", classes = "" }) => (
    <svg
        className={`relative ${classes}`}
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        style={arrowStyle}>
        <path
            d='m14.707 12.707-4 4a1 1 0 0 1-1.414-1.414L12.586 12 9.293 8.707a1 1 0 1 1 1.414-1.414l4 4a1 1 0 0 1 0 1.414z'
            fill={color}
        />
    </svg>
);