import React from "react";
import type { HeadFC } from "gatsby";
import BrandsScreen from "../../screens/BrandsScreen";

const BrandsPage = () => {
    return (
        <>
            <BrandsScreen />
            <div id='portal' />
        </>
    );
};

export default BrandsPage;

export const Head: HeadFC = () => <title>Our Brands</title>;
