export const headersTabs = [
    {
        name: "OUR BRANDS",
        link: "/brands",
    },
    // {
    //     name: "OUR HISTORY",
    //     link: "/history",
    // },
    {
        name: "STYLE GUIDES",
        link: "/style-guide",
    },
];