import React from "react";

const CrossSign = ({classes=''}) => (
    <svg
        className={`${classes} h-6 w-6`}
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'
        aria-hidden='true'>
        <path
            stroke-linejoin='round'
            stroke-width='4'
            d='M6 18L18 6M6 6l12 12'
        />
    </svg>
);

export default CrossSign;