// extracted by mini-css-extract-plugin
export var activeSlider = "header-module--activeSlider--4ecd1";
export var desktopMargin = "50px";
export var doublexl = "1536";
export var header = "header-module--header--576fe";
export var headerAfterScroll = "header-module--headerAfterScroll--bb032";
export var headerHeight = "10vh";
export var headerLogo = "header-module--headerLogo--fd02e";
export var headerNoShrink = "header-module--headerNoShrink--e0549";
export var lg = "1024";
export var md = "768";
export var mobileMargin = "18px";
export var navMenu = "header-module--navMenu--f4679";
export var sm = "640";
export var xl = "1280";