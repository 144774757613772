import React, { FC } from "react";
import BrandCard from "../../components/BrandCard";
import Carousel from "../../components/Carousel";
import Header from "../../components/Header";
import Spacer from "../../components/Spacer";
import useIsMobileViewport from "../../hooks/useIsMobileViewport";
import { BrandScreenData } from "./data";

export interface BrandsScreenProps {}

const BrandsScreen: FC<BrandsScreenProps> = (props) => {
    const { isMobile } = useIsMobileViewport();
    return (
        <div className='brands-screen'>
            <Header />
            <div className='relative overflow-x-hidden'>
                <Carousel
                    hasArrows
                    isVertical={isMobile}
                    fullScreenWithHeader
                    isInfiniteScroll
                    hasSlideDivider>
                    {BrandScreenData(isMobile).map((data, id) => {
                        return <BrandCard {...data} fullScreenWithHeader />;
                    })}
                </Carousel>
            </div>
        </div>
    );
};

export default BrandsScreen;
