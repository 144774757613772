import React, { ReactNode, FC } from "react";
import logo from "../../assets/images/Gibson_Brands_Black.png"; 
import * as styles from "./header.module.sass";
import { Link } from "gatsby";

export interface GibsonHeaderLogo {
    // height?: string;
    // width?: string;
}



const GibsonHeaderLogo: FC<GibsonHeaderLogo> = ({ }) => {
    return (
        <Link to={'/'} >
            <div className={`flex relative md:absolute justify-center md:justify-start top-[-100vh] md:top-auto ${styles.headerLogo}`} >  
                <div
                    className='sticky top-0 absolute h-fit z-5 w-fit bg-white'>
                    <img src={logo} />
                </div>
            </div>
        </Link>
    );
};

export default GibsonHeaderLogo;
