import { useState, useEffect } from 'react';
import * as styles from './variables.module.sass';

const useIsMobileViewport = () => {
  const isBrowser = typeof window !== "undefined";

  if (!isBrowser) {
      return { isMobile: false };
  }
  
  const [isMobile, setIsMobile] = useState<boolean>(
      window.innerWidth <= parseInt(styles.mobile, 10)
  );

  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(
      window.innerWidth <= parseInt(styles.bigScreen, 10)
  );

  useEffect(() => {
    setIsMobile(window.innerWidth <= parseInt(styles.mobile, 10));
    setIsSmallScreen(window.innerWidth <= parseInt(styles.bigScreen, 10));
    const handleWindowResize = () =>{
      setIsMobile(window.innerWidth <= parseInt(styles.mobile, 10));
      setIsSmallScreen(window.innerWidth <= parseInt(styles.bigScreen, 10));
    }
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return { isMobile, isSmallScreen };
};

export default useIsMobileViewport;
