import clsx from "clsx";
import React, { FC } from "react";
import { Arrow } from "../../assets/svg/Arrow";
import scrollTo from "gatsby-plugin-smoothscroll";
import { Link } from "gatsby";


export interface ButtonProps {
    classes?: string;
    isGold?: boolean;
    isBlack?: boolean;
    hasUpperCase?: boolean;
    hasArrow?: boolean;
    arrowAtStart?: boolean;
    scroll?: boolean;
    arrowColor?: string;
    internalLink?: string;
    externalLink?: string;
    children: React.ReactNode;
    onClick?: () => void;
    arrowClasses?: string;
}

const Button: FC<ButtonProps> = ({
    classes,
    isGold = false,
    isBlack = false,
    hasUpperCase = true,
    hasArrow = false,
    arrowAtStart = false,
    scroll = false,
    arrowColor = "white",
    internalLink,
    externalLink,
    children,
    onClick,
    arrowClasses,
}) => {
    const btnClass = clsx(
        "btn cursor-pointer",
        hasUpperCase ? "uppercase" : "",
        isGold ? "btn-gold" : "",
        isBlack ? "btn-black" : "",
        classes
    );

    if (internalLink && scroll)
        return (
            <button onClick={() => scrollTo(internalLink)} className={btnClass}>
                {hasArrow && arrowAtStart && (
                    <Arrow
                        color={arrowColor}
                        classes={`rotate-180 ${arrowClasses}`}
                    />
                )}
                {children}
                {hasArrow && !arrowAtStart && (
                    <Arrow color={arrowColor} classes={arrowClasses} />
                )}
            </button>
        );

    if (internalLink) {
        return (
            <Link to={internalLink} className={btnClass}>
                {hasArrow && arrowAtStart && (
                    <Arrow
                        color={arrowColor}
                        classes={`rotate-180 ${arrowClasses}`}
                    />
                )}
                {children}
                {hasArrow && !arrowAtStart && (
                    <Arrow color={arrowColor} classes={arrowClasses} />
                )}
            </Link>
        );
    }

    if (externalLink) {
        return (
            <a href={externalLink} target='_blank' className={btnClass}>
                {hasArrow && arrowAtStart && (
                    <Arrow
                        color={arrowColor}
                        classes={`rotate-180 ${arrowClasses}`}
                    />
                )}
                {children}
                {hasArrow && !arrowAtStart && (
                    <Arrow color={arrowColor} classes={arrowClasses} />
                )}
            </a>
        );
    }

    return (
        <button className={btnClass} onClick={onClick}>
            {hasArrow && arrowAtStart && (
                <Arrow
                    color={arrowColor}
                    classes={`rotate-180 ${arrowClasses}`}
                />
            )}
            {children}
            {hasArrow && !arrowAtStart && (
                <Arrow color={arrowColor} classes={arrowClasses} />
            )}
        </button>
    );
};

export default Button;
