import React, { ReactNode, FC, useState } from "react";
import {headersTabs} from './headerItems';
import NavMenuLogo from "../../assets/svg/NavMenuLogo.svg"
import GibsonHeaderLogo from "./GibsonHeaderLogo";
import useIsMobileViewport from "../../hooks/useIsMobileViewport";
import Button from "../Button";
import CrossSign from "../../assets/svg/CrossIcon";
import * as styles from "./header.module.sass";
import { Link } from "gatsby";

export interface HeaderProps {
    classes?: string;
    shrinkOnScroll?: boolean;
    mobileAfterScrollView?: boolean;
}

export const headerHeight = styles.headerHeight;

const headerStyle = {
    height: headerHeight,
    // zIndex: 1,
};

const Header: FC<HeaderProps> = ({
    classes,
    shrinkOnScroll,
    mobileAfterScrollView,
}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { isMobile } = useIsMobileViewport();

    return (
        <>
            {!mobileAfterScrollView && <GibsonHeaderLogo />}
            <nav
                className={`z-[2] sticky bottom-0 top-0 pl-0 p-5 drop-shadow flex justify-start md:justify-end items-center bg-black text-white ${classes} ${
                    shrinkOnScroll ? styles.header : styles.headerNoShrink
                } ${mobileAfterScrollView ? styles.headerAfterScroll : ""}`}>
                <div className='max-w-[45vw] text-sm hidden md:flex'>
                    {headersTabs.map((tabs, index) => {
                        return (
                            <Button internalLink={tabs.link}>
                                {tabs.name}
                            </Button>
                        );
                    })}
                </div>
            </nav>
            <div
                className='flex md:hidden p-4 z-10 text-white mix-blend-difference fixed top-0 h-[10vh] items-center'
                onClick={() => {
                    setIsMenuOpen(!isMenuOpen);
                }}>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='w-6 h-6'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'>
                    <path
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        d='M4 6h16M4 12h16M4 18h16'
                    />
                </svg>
            </div>
            <div
                className={`overflow-y-hidden h-screen top-0 left-0 absolute bg-cover w-fit bg-no-repeat z-10 text-white ${
                    styles.navMenu
                } ${isMenuOpen ? styles.activeSlider : ""}`}>
                <button
                    type='button'
                    className='p-4 absolute inline-flex items-center justify-center text-white focus:outline-none'
                    onClick={() => {
                        setIsMenuOpen(!isMenuOpen);
                    }}>
                    <span className='sr-only'>Close menu</span>
                    <CrossSign />
                </button>
                <div className='h-screen flex flex-col items-center py-8'>
                    <Link to={"/"}>
                        <img src={NavMenuLogo} alt='NavMenuLogo' />
                    </Link>

                    <div className='flex flex-col items-center w-full justify-evenly h-4/6 text-xl font-bold'>
                        {headersTabs.map((tabs, index) => {
                            return <a href={tabs.link}>{tabs.name}</a>;
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;
